@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --colors-clr-1: #010013;
  --colors-clr-2: #0026ff;
  --colors-clr-3: #01107c;
  --colors-clr-4: #021db4;
  --colors-clr-5: #000720;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}
ul,
li,
body,
html {
  transition: none;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', sans-serif;
}
@keyframes bounce {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}
.down-arrow {
  animation: bounce 0.8s infinite;
}
/****************************************** Nav *****************************************/
.hidden-nav {
  transform: translateY(-100%);
}
nav .menu-items li {
  text-shadow: 0 0 1.5px rgba(255, 255, 255, 1);
}

nav .menu-items li:hover {
  color: var(--colors-clr-2);
  text-shadow: none;
  transition: all 300ms ease-in-out;
}

nav svg {
  transition: all 300ms ease-in-out;
}

.rotated-bar1 {
  transform: rotate(45deg);
  transform-origin: top left;
}
.rotated-bar2 {
  transform: rotate(-45deg);
  transform-origin: bottom left;
}
@keyframes upToBottomAnimation {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}
@keyframes BottomToTopAnimation {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}
.mobile-menu-items {
  visibility: hidden;
}
.show-mobile-menu {
  animation: upToBottomAnimation 0.5s ease-in-out;
  transition: transform 300ms ease-in-out;
  visibility: visible !important;
}
.hide-mobile-menu {
  animation: BottomToTopAnimation 0.5s ease-in-out;
  height: 0;
  visibility: hidden !important;
}

/****************************************** Main *****************************************/

.hovering-effect-div {
  width: 0px;
  height: 0px;
  box-shadow: 0 0 350px 250px var(--colors-clr-4);
  opacity: 0.4;
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: var(--colors-clr-2);
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.typed-out {
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid var(--colors-clr-2);
  width: 0;
  animation: typing 2.5s steps(20, end) forwards, blink 0.9s infinite;
}
@keyframes leftToRightAnimation {
  0% {
    transform: translateX(-300%);
    filter: blur(20px);
    opacity: 0;
  }
  70% {
    opacity: 0.45;
  }
  100% {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes rightToLeftAnimation {
  0% {
    transform: translateX(300%);
    filter: blur(20px);
    opacity: 0;
  }
  70% {
    opacity: 0.45;
  }
  100% {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
}
.animate-text {
  animation: leftToRightAnimation 1s ease-in-out;
  transform: translateX(0);
  transition: none;
}
.animate-description {
  animation: rightToLeftAnimation 1s ease-in-out;
  transform: translateX(0);
  opacity: 1;
  transition: none;
}
/**************************************** biography ****************************************/

/******************************** CAREER **********************************/
.career-card {
  background: linear-gradient(
    141deg,
    var(--colors-clr-1) -4.73%,
    var(--colors-clr-2) 48.79%,
    var(--colors-clr-3) 71.99%,
    var(--colors-clr-4) 120.81%,
    var(--colors-clr-5) 213.56%
  );
  background-position: 100%;
  background-size: 150%;
  border-radius: 0.7975rem;
  transition: 0.3s ease-in-out;
}
.career-card:hover {
  background-position: 20%;
}

/********************* SKILLS  ***********************************/
#skills img {
  width: 45px;
  height: 45px;
}
@media (min-width: 540px) {
  #skills img {
    width: 60px;
    height: 60px;
  }
}

/****************** Projects  ******************************/
.swiper-pagination-bullet {
  background-color: white !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--colors-clr-2) !important;
}
